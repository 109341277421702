import React, { useEffect } from 'react';
import { useMe } from '../../hooks/useMe';
import { useUser } from '../../hooks/useUser';
import useScript from '../../hooks/useScript';
import { PLANS } from '../../constants';
import AccountLayout from './components/accountSettingsLayout'
import tw from 'twin.macro'
import byteSize from 'byte-size'
import _get from "lodash.get"
import { CognitoUser } from '../../classes/user'

function getCurrentMonthYear() {
	const currentDate = new Date();
	const month = String(currentDate.getMonth() + 1).padStart(2, '0');
	const year = String(currentDate.getFullYear()).slice(-2);
	return month + year;
}
 
const Plan = ({...props}) => {

	const { me } = useMe()
	const { user } = useUser()

	const portalScriptStatus = useScript('https://checkoutlib.billsby.com/checkout.min.js')

	useEffect(() => {
		if(
			portalScriptStatus === 'ready' && 
			window && 
			window.scanDomBillsby &&
			(user instanceof CognitoUser)
		) {
			window.billsbyData = {
				email: user.email,
				customFields: [
					{
						customFieldId: 2864,
						value: user.username
					}
				]
			};
			window.scanDomBillsby();
		}
	})

	let byteSizes
	if (me) {
		byteSizes = {
			maxTransfer: byteSize(me.maxUploadBytes),
			maxStorage: byteSize(me.maxStorageBytes),
			usedStorage: byteSize(me.usedStorageBytes)
		}
	}

	
	const paymentSubscriptionIdFull = _get(me, "paymentSubscriptionId") || "";

	const [paymentSubscriptionId, planId, cycle, ccExp] = paymentSubscriptionIdFull.split("_")
	
	const isBillsy = Boolean(paymentSubscriptionId) && !planId

	const ccExpired = ccExp && getCurrentMonthYear() > ccExp

	return <AccountLayout tab="plan" {...props}>
		{ me ? <div>
			{me.paymentSubscriptionId && 
				(!isBillsy ?
					ccExpired ? 
						<p>Your credit card on file has expired. Please <a 
							href="javascript:void(0)" 
							data-billsby-type="checkout"
							data-billsby-product="10992" 
							data-billsby-plan="15318"
							data-billsby-cycle={cycle === "M" ? "18622" : "18621"}
							data-billsby-redirect={`${process.env.GATSBY_SITE_URL}/plans/verify`}
							>click here to update your payment method</a>.
						</p> :
						<p>To change or cancel your plan subscription, or for other requests regarding plan billing, please contact <a href="mailto:support@file.io">file.io support</a>.</p> :
					<p>To change or cancel your plan, update your payment method or personal details, or to view your billing history, please <a href="javascript:void(0)" data-billsby-type="account" data-billsby-customer={me.paymentCustomerId} data-billsby-subscription={me.paymentSubscriptionId}>click here to access your subscription portal</a>.</p>
				)}
			<div tw="my-4 grid grid-cols-12 gap-4">
				<div tw="col-span-3 font-semibold">Current Plan</div>
				<div tw="col-span-9">file.io {PLANS[me.planId].name}</div>
				<div tw="col-span-3 font-semibold">Max Upload Size</div>
				<div tw="col-span-9">{byteSizes.maxTransfer.value} {byteSizes.maxTransfer.unit.toUpperCase()}</div>
				<div tw="col-span-3 font-semibold">Max Storage</div>
				<div tw="col-span-9">{byteSizes.maxStorage.value} {byteSizes.maxStorage.unit.toUpperCase()}</div>
				<div tw="col-span-3 font-semibold">Used Storage</div>
				<div tw="col-span-9">{byteSizes.usedStorage.value} {byteSizes.usedStorage.unit.toUpperCase()} ({(parseFloat(me.usedStorageBytes) * 100.0 / parseFloat(me.maxStorageBytes)).toFixed(2)}%)</div>
			</div>
		</div> : 
		<div></div> 
		}
	</AccountLayout>
};

export default Plan
